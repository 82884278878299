<template>
  <section class="py-5">
    <request-materials
      class="mt-4"
      @requestMaterialsCreated="getRequestMaterials"
      ref="request-materials"
    ></request-materials>
  </section>
</template>

<script>
import RequestMaterials from "../requestMaterials/index.vue";
import { authUsuarioComputed } from "@/state/helpers";
export default {
  components: {
    RequestMaterials,
  },
  data() {
    return {
      project_code: this.$route.query.sap_project,
    };
  },
  methods: {
    async getRequestMaterials() {
      this.$refs["request-materials"].getRequestMaterialsData(1);
    },
  },
  computed: {
    ...authUsuarioComputed,
  },
};
</script>

<style></style>
